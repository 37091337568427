<template lang="pug">
  div.basic100(style="min-height: 100vh")
    div.basic100.py-1(dense flat)
      v-container(style="max-width: 600px")
        .h6.primary500--text {{ $t('payment_error') }}
    v-container.pa-0(style="max-width: 600px")
      .content.basic100.py-4
        div.py-6
          v-img.mx-auto(:src="require('@/assets/images/bg_order_failed.png')" height="50" width="50" contain)
        div.text-center
          .h6.danger500--text.my-4 {{ $t('payment_failed') }}
          .p1.mt-1 {{ $t('payment_for_order_could_not', { text: lead.rid }) }}
          div.d-flex.justify-center
            v-btn.button-AA-small.basic100--text.my-4(color="danger500" depressed @click="verifyPayment") {{ $t('verify_again') }}
            v-btn.button-AA-small.basic100--text.my-4.ml-4(v-if="$route.query.retry" color="danger500" depressed @click="reportPayment" :loading="isLoading") {{ $t('report_payment_issue') }}
      order-summary.mt-4.mx-4(:isReadOnly="true")
      div.text-center.mt-6
        .p1 {{ $t('click_on_button_below') }}
        v-btn.button-AA-small.basic100--text.my-4(color="danger500" depressed @click="clickViewCheckout") {{ $t('back_to_checkout') }}

</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import OrderSummary from '@/components/viewmodels/OrderSummary.vue'

export default {
  name: 'OnlinePaymentFailed',
  components: { OrderSummary },
  data: () => ({
    isLoading: false
  }),
  computed: {
    ...mapGetters({
      lead: 'current/getLead'
    })
  },
  mounted () {
    if (this.lead.paid) {
      this.$router.push(`/orders/${this.lead.pid}`)
      return
    }
  },
  methods: {
    clickViewCheckout () {
      if (this.lead.cod || this.lead.pay_at_shop) {
        this.$router.push(`/orders/${this.lead.pid}`)
        return
      }
      this.$router.push(`/checkout/${this.lead.pid}`)
    },
    verifyPayment() {
      this.$axios.post(`/leads/${this.lead.pid}/recheck_payments/`)
        .then(() => {
          location.href = `https://${this.lead.hostname}/invoices/${this.lead.pid}/verify?retry=true`
        })
    },
    reportPayment() {
      this.isLoading = true
      const params = new URLSearchParams()
      params.append('invoice', `https://${this.lead.hostname}/invoices/${this.lead.pid}` )
      params.append('lead_id', this.lead.id)
      params.append('company_id', this.lead.form.company.id)
      axios.post('https://hooks.zapier.com/hooks/catch/1847976/b3jbg4w/', params, { headers: {'Content-Type': 'application/x-www-form-urlencoded' } })
        .then(() => {
          this.$store.commit('message/showMessage', ['success', 'Your concern has been reported for investigation.'])
          this.isLoading = false
        })
    }
  }
}
</script>

<style scoped>
</style>